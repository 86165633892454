body {
    padding: 0;
    margin: 0;
  }
  
  .App {
    height: 100vh;
    width: 100%;
  }
  
  /* .circle {
    background-color: #d83b01;
    border-radius: 50%;
    border:2px solid black;
    color: #fff;
    height: 20px;
    position: relative;
    width: 20px;
    text-align: center;
  } */
  .circle {
    background: #f44336;
    width: 25px; 
    height: 25px;
    color: #ffebee;
    border-radius: 50%;
    border:2px solid #800008;
    display: flex; /* or inline-flex */
    align-items: center; 
    justify-content: center;
  }
  /* .circleText {
    text-align: center;
    color: #ffffff;
    height: 10px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
  } */
  
  .hover {
    background-color: #3f51b5;
    border:2px solid black;
  }
  
  .post_popup{
      width: 400px
  }