.post {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid grey;
    padding-bottom: 10px;
    width: 100%;
  }
  
  .post__body {
    flex: 1;
    /* padding: 10px; */
  }
  
  .post__avatar {
    padding: 20px;
  }
  
  .post__body > img {
    border-radius: 20px;
    width: 100%;
  }
  
  .post__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .post__badge {
    font-size: 14px !important;
    color: #50b7f5;
  }
  
  .post__headerText > h3 {
    margin-top:5px;
    font-size: 15px;
    margin-bottom: 5px;
  }
  
  .post__headerSpecial {
    font-weight: 600;
    font-size: 12px;
    color: grey;
  }
  .post__headerSpecial > a{
    color: grey;
  }
  
  .post__headerDescription {
    margin-bottom: 5px;
    font-size: 15px;
  }
  a {
    color: #000000;
  }
  a:hover {
     color: #000000
  }