/* .page{
  background: #F2f2f2;
} */
.headerBackground {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background-color: #ffffff
}
.footerBackground {
  position: fixed;
  bottom: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background-color: #ffffff
}
.main-edit-table {
    width: 100%;
}
.twitter-feed {
    border: 1px solid #D3D3D3;
    border-radius: 4px;
    height: 88vh;
    overflow-y: scroll;
    width:100%;
    background:#FFFFFF
}
.twitter-feed::-webkit-scrollbar {
    width: 20px;
  }
  
  /* Track */
  .twitter-feed::-webkit-scrollbar-track {
    background-color: transparent; 
    /* border-radius: 5px; */
  }
   
  /* Handle */
  .twitter-feed::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
    height: 50px
  }
  
  /* Handle on hover */
  .twitter-feed::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
.card1 {
  border: 1px solid #D3D3D3;
  border-radius: 4px;
  height: 35vh;
  background: #FFFFFF;
  /* max-height: 35vh; */
  /* overflow-y: scroll; */
  width:100%;
  /* display:flex; */
  /* padding:10px */
}
.card2 {
  /* border: 1px solid #D3D3D3;
  border-radius: 4px; */
  height: 36vh;
  /* background: #ffffff; */
  /* max-height: 35vh; */
  /* overflow-y: scroll; */
  width:100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* display:flex; */
  /* padding:10px */
}
.graph-body {
  color: #47a8b9;
  height: 35vh;
  /* max-height: 35vh; */
  /* overflow-y: scroll; */
  width:100%;
}
.map {
  border: 1px solid #D3D3D3;
  border-radius: 4px;
  /* max-height: 55vh; */
  /* overflow-y: scroll; */
  width: 100%;
  /* height: 45vh; */
}